body {
    margin: 0;
    padding: 0;
}

.text-center {
    text-align: center;
}

img {
    display: inline-block;
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid  transparent;
    border-radius: 4px;
    text-decoration: none;
}

.btn-primary {
    background-color: #e57b1b;
    border-color: #e57b1b;
    color: #fff;
}

.wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background: #00619D;
}

.header {
    width: 100%;
    padding: 10px 0;
}

.main {
    font-family: inherit;
    font-style: normal;
    color: #fff;
    position: relative;
    
    img {
        width: 100%;
        min-height: 220px;
    }
    
    .ooops {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 60px;
        text-transform: capitalize;
        font-weight: 700;
        text-align: center;
    }
}

.error-footer {
    flex-grow: 1;
    background: #fff;
    position: relative;
    height: 100%;
    padding: 0 10px;
}

.not-found-details {
    h2 {
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
    }
    h4 {
        font-size: 20px;
        font-style: normal;
        font-weight: 200;
        line-height: 27px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .main .ooops {
        top: 30%;
        font-size: 50px;
    }
    
    h2 {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .main .ooops {
        top: 30%;
    }
}
